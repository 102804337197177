import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, IContacts, OPTIONS } from '../interfaces';
import { fetcher } from '../fetcher';
import { useHandleUnauthorized } from '..';

const useGetContacts = (): IApiResponseReturn<IContacts[]> => {
  const { data, error } = useSWR<IApiResponse<IContacts[]>>(EApiUrls.CONTACTS, fetcher, OPTIONS);

  useHandleUnauthorized(error?.status || null);

  if (error) {
    console.error('Ошибка при загрузке контактных данных: ', error);
  }

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetContacts;
