import React, { useState } from 'react';
import { Button, EButtonClass, EButtonType, InputPassword, SmallNotification } from '../../components';
import { PageLayout } from '../../layout';
import CryptoJS from 'crypto-js';
import { CustomMsgs, setCookie, StatusCode, URLS } from '../../../utils';
import { EApiUrls, useGetUserInfo } from '../../../api';
import { HASHING, SESSION_ID_COOKIE } from '../../../utils/consts';
import { useNavigate } from 'react-router-dom';

const FAIL_FIELD_EMPTY: string = 'Все поля должны быть заполнены';
const FAIL_FETCH: string = 'Произошла ошибка при смене пароля';

const ChangePasswordPage: React.FC = () => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState<boolean>(false);
  const [isOpenAuthNotification, setIsOpenAuthNotification] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: userInfoData } = useGetUserInfo();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setErrorMessage(null);

    const { oldPassword, password: newPassword, confirmPassword } = formData;

    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage(FAIL_FIELD_EMPTY);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage(CustomMsgs.PASSWORD_NO_MATCH);
      return;
    }

    const useHashing = HASHING === 'true';
    const finalPassword = useHashing ? CryptoJS.MD5(formData.password).toString() : formData.password;
    const finalConfirmPassword = useHashing
      ? CryptoJS.MD5(formData.confirmPassword).toString()
      : formData.confirmPassword;

    try {
      const response = await fetch(EApiUrls.CHANGE_PASSWORD_USER, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userInfoData?.id,
          old_password: oldPassword,
          password: finalPassword,
          confirm_password: finalConfirmPassword,
        }),
      });

      const { data } = await response.json();

      if (response.ok) {
        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }
        setIsSuccessUpdate(true);
        setTimeout(() => navigate(URLS.ACCOUNT), 2000);
      } else if (response.status === StatusCode.NO_AUTH) {
        setIsOpenAuthNotification(true);
        setTimeout(() => navigate(URLS.LOGIN), 2000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    }
  };

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[26px] px-[16px] rounded-lg flex-1 flex-col justify-center">
        <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Смена пароля</h2>

        <InputPassword
          label="Текущий пароль"
          placeholder="Введите текущий пароль"
          value={formData.oldPassword}
          name="oldPassword"
          onChange={handleInputChange}
        />

        <InputPassword
          label="Новый пароль"
          placeholder="Введите новый пароль"
          value={formData.password}
          name="password"
          onChange={handleInputChange}
        />

        <InputPassword
          label="Подтвердите новый пароль"
          placeholder="Подтвердите новый пароль"
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleInputChange}
        />
      </div>
      {errorMessage && <div className="text-[red] px-[16px] mb-4">{errorMessage}</div>}

      <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
        <Button
          text="Сохранить изменения"
          nameClass={EButtonClass.DEF}
          isLink={false}
          typeBtn={EButtonType.SUBMIT}
          handle={handleSubmit}
        />
      </div>
      {isSuccessUpdate && <SmallNotification text="Пароль успешно обновлен" />}
      {isOpenAuthNotification && <SmallNotification text={CustomMsgs.NO_AUTH_LOG} isSuccess={false} />}
    </PageLayout>
  );
};

export default ChangePasswordPage;
