import { useEffect, useState } from 'react';
import { getFetchCities, IApiResponse, ICity } from '../';

const useGetCities = (regionId: string | null) => {
  const [response, setResponse] = useState<IApiResponse<ICity[]> | null>(null);

  useEffect(() => {
    const fetchCities = async () => {
      if (regionId) {
        try {
          const data = await getFetchCities(regionId);
          setResponse(data);
        } catch (error) {
          console.error('Ошибка при загрузке городов: ', error);
          setResponse(null);
        }
      } else {
        setResponse(null);
      }
    };

    fetchCities();
  }, [regionId]);

  return response;
};

export default useGetCities;
