import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, IPoints, OPTIONS } from '../interfaces';
import { fetcher } from '../fetcher';
import { useHandleUnauthorized } from '..';

const useGetPoints = (): IApiResponseReturn<IPoints> => {
  const { data, error } = useSWR<IApiResponse<IPoints>>(EApiUrls.POINTS, fetcher, OPTIONS);

  useHandleUnauthorized(error?.status || null);

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetPoints;
