import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { StatusCode, URLS } from '../../utils';
import { BASE_URL, SESSION_ID_COOKIE } from '../../utils/consts';

const useHandleUnauthorized = (code: number | null) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentFullPath = `${BASE_URL}${location.pathname}`;

    const noRedirectPaths: string[] = [
      `${BASE_URL}${URLS.LOGIN}`,
      `${BASE_URL}${URLS.REGISTER}`,
      `${BASE_URL}${URLS.SIGN_PHONE}`,
      `${BASE_URL}${URLS.RESET_PASS}`,
      `${BASE_URL}${URLS.START}`,
      `${BASE_URL}${URLS.HOME}`,
    ];

    if (code === StatusCode.NO_AUTH && !noRedirectPaths.some((path) => path === currentFullPath)) {
      document.cookie = `${SESSION_ID_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      navigate(URLS.LOGIN);
    }
  }, [code, location, navigate]);
};

export default useHandleUnauthorized;
