import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, ITransaction } from '../interfaces';
import { fetcher } from '../fetcher';
import { useHandleUnauthorized } from '..';

const useGetTransactions = (): IApiResponseReturn<ITransaction[]> => {
  const { data, error } = useSWR<IApiResponse<ITransaction[]>>(EApiUrls.GET_TRANSACTIONS, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 100000,
    dedupingInterval: 1000,
    revalidateIfStale: true,
  });

  useHandleUnauthorized(error?.status || null);

  if (error) {
    console.error('Ошибка при загрузке данных о транзакциях: ', error);
  }

  return {
    data: data?.data || [],
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetTransactions;
