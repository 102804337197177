import React from 'react';
import { CrossIcon, EInputType, ImageIcon } from '.';

interface PassportUploadProps {
  uploadedFiles: File[];
  filePreviews: string[];
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveFile: (index: number) => void;
}

const PassportUpload: React.FC<PassportUploadProps> = ({
  uploadedFiles,
  filePreviews,
  handleDrop,
  handleDragOver,
  handleFileSelect,
  handleRemoveFile,
}) => {
  return (
    <div className="mt-[32px] mb-[40px]">
      <h2 className="text-[18px] leading-[21.6px] font-medium text-main">Подтверждение паспортных данных</h2>
      <p className="text-sm text-main mt-[16px]">
        Загрузите фотографии второй, третьей и пятой страниц паспорта, чтобы наш менеджер сверил данные.
      </p>

      <div className="w-full flex flex-col mt-[24px] justify-center">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="grid grid-cols-2 gap-4 bg-[#F8F8F8] p-[20px] rounded-xl justify-center items-start"
        >
          <div className="flex flex-col justify-center items-center cursor-pointer border border-dashed border-gray-300 p-4 rounded-lg">
            <input
              type={EInputType.FILE}
              accept="image/*"
              multiple
              onChange={handleFileSelect}
              className="hidden"
              id="fileUpload"
            />
            <label
              htmlFor="fileUpload"
              className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
            >
              <ImageIcon className="h-[14px] w-[14px]" />
              <p className="text-main text-sm pt-4 text-center">Добавить фото паспорта</p>
            </label>
          </div>

          {filePreviews.map((preview, index) => (
            <div key={index} className="relative">
              <CrossIcon
                className="absolute top-[16px] right-[-12px] h-[18px] w-[18px] cursor-pointer"
                onClick={() => handleRemoveFile(index)}
              />
              <img src={preview} alt={`Passport page ${index + 1}`} className="w-full h-auto object-cover rounded-lg" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PassportUpload;
