import React from 'react';
import { Loading } from '../../components';
import { PageLayout } from '../../layout';
import { useGetContacts } from '../../../api';
import { Link } from 'react-router-dom';

const ContactsPage: React.FC = () => {
  const { data, isLoading } = useGetContacts();

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[20px] px-[16px] pb-[88px] rounded-lg flex-1 justify-center self-start">
        <h2 className="font-medium mb-[20px] text-[24px] text-header text-start">Помощь и контакты</h2>
        <p className="text-main font-normal text-sm mb-[32px]">
          Телефон справочной службы работает с 4 до 17 часов по московскому времени (по России бесплатно)
        </p>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="mb-[40px] w-full">
            <ul className="list-none p-0">
              {data?.map((item) => (
                <li className="my-[12px]" key={item.id}>
                  <Link className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between" to={item.link}>
                    <div className="flex items-center">
                      <div className="p-2 rounded-lg bg-white">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div>
                        <p className="pl-[12px] text-sm font-medium">{item.name}</p>
                        <p className="pl-[12px] text-xs font-normal">{item.text}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ContactsPage;
