import { CustomMsgs } from '../utils';
import { EApiUrls, ICity, ICountry, IRegion, IApiResponse } from './interfaces';

const fetchData = async <T>(url: string): Promise<IApiResponse<T> | null> => {
  try {
    const response = await fetch(url, { cache: 'force-cache', credentials: 'include' });

    if (!response.ok) {
      throw new Error(`${CustomMsgs.HTTP}${response.status}`);
    }

    const json: IApiResponse<T> = await response.json();
    return json;
  } catch (e) {
    console.error(CustomMsgs.FETCH, e);
    return null;
  }
};

export const getFetchCities = async (id: string): Promise<IApiResponse<ICity[]> | null> =>
  fetchData<ICity[]>(`${EApiUrls.CITIES}${id}`);

export const getFetchCountries = async (): Promise<IApiResponse<ICountry[]> | null> =>
  fetchData<ICountry[]>(EApiUrls.COUNTRIES);

export const getFetchRegions = async (id: string): Promise<IApiResponse<IRegion[]> | null> =>
  fetchData<IRegion[]>(`${EApiUrls.REGIONS}${id}`);
