import { IOption } from '../app/components/inputs/InputSelect';

export enum Gender {
  MALE = 'Мужской',
  FEMALE = 'Женский',
}

export const GenderMap: { [key: string]: Gender } = {
  1: Gender.MALE,
  2: Gender.FEMALE,
};

export const GenderOptions: IOption[] = [
  { id: 1, name: Gender.MALE },
  { id: 2, name: Gender.FEMALE },
];
