import React from 'react';
import { EmptyTransactions, Transactions, Loading } from '../../components';
import { PageLayout } from '../../layout';
import { useGetTransactions } from '../../../api';

const HistoryPage: React.FC = () => {
  const { data, isLoading, isError } = useGetTransactions();

  return (
    <PageLayout isBtnBack={false}>
      {isLoading ? (
        <Loading />
      ) : !data || data.length === 0 ? (
        <EmptyTransactions />
      ) : (
        <Transactions transactions={data} isLoading={isLoading} isError={isError} />
      )}
    </PageLayout>
  );
};

export default HistoryPage;
