import useSWR from 'swr';
import { EApiUrls, fetcher, IApiResponse, IApiResponseReturn, ICatalogCard, OPTIONS, useHandleUnauthorized } from '..';

const useProductCard = (productId: number): IApiResponseReturn<ICatalogCard> => {
  const { data: catalogData, error: catalogError } = useSWR<IApiResponse<ICatalogCard[]>>(
    EApiUrls.CATALOG,
    fetcher,
    OPTIONS
  );

  const productFromCache = catalogData?.data.find((product) => product.id === productId);

  const { data: fetchData, error: fetchError } = useSWR<IApiResponse<ICatalogCard[]>>(
    !productFromCache ? EApiUrls.CATALOG : null,
    fetcher,
    OPTIONS
  );

  useHandleUnauthorized(fetchError?.status || null);

  const product = productFromCache || fetchData?.data.find((product) => product.id === productId);
  const isLoading = !product && !fetchError && (!fetchData || !productFromCache);
  const isError = catalogError || fetchError;

  return {
    data: product || null,
    code: catalogData?.code || null,
    message: catalogData?.message || null,
    time: catalogData?.time || null,
    isLoading,
    isError: !!isError,
  };
};

export default useProductCard;
