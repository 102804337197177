import React, { useState } from 'react';
import {
  Button,
  EButtonClass,
  EButtonType,
  EInputType,
  SmallNotification,
  SuccessNotification,
  ConfirmAction,
} from '../../components';
import { EApiUrls } from '../../../api';
import { CustomMsgs, StatusCode, URLS } from '../../../utils';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';

const pricesValues: number[] = [1000, 5000, 10000, 15000, 20000];
const FAIL_FETCH: string = 'Ошибка при заказе денежного приза. Проверьте правильность данных и попробуйте снова';
const SUCCESS_MSG: string = 'Приз успешно заказан.';

const PrizesCategory: React.FC = () => {
  const [priceValue, setPriceValue] = useState<number | null>();
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>();
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [isOpenConfirmOrder, setIsOpenConfirmOrder] = useState<boolean>(false);
  const [isOpenAuthNotification, setIsOpenAuthNotification] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = Number(e.target.value);
    setPriceValue(value);

    const index: number = pricesValues.findIndex((val) => val === value);
    if (index !== -1) {
      setHighlightedIndex(index);
    } else {
      setHighlightedIndex(undefined);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${EApiUrls.ORDER}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: priceValue }),
      });

      const data = await response.json();

      if (response.ok) {
        setNotificationMessage(SUCCESS_MSG);
        mutate(EApiUrls.POINTS);
        mutate(EApiUrls.GET_TRANSACTIONS);
      } else if (response.status === StatusCode.NO_AUTH) {
        setIsOpenAuthNotification(true);
        setTimeout(() => navigate(URLS.LOGIN), 2000);
      } else {
        setNotificationMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setNotificationMessage(FAIL_FETCH);
    } finally {
      setPriceValue(null);
      setHighlightedIndex(null);
      setIsOpenNotification(true);
    }
  };

  const isSubmitDisabled =
    !priceValue || priceValue < pricesValues[0] || priceValue > pricesValues[pricesValues.length - 1];

  return (
    <div className="flex flex-col justify-between flex-grow pb-[112px]">
      <div>
        <h2 className="text-[18px] leading-[21.6px] text-main font-medium mb-[20px] pr-[16px] pl-[16px]">
          Введите сумму приза
        </h2>
        <div className="w-full mb-[4px] pr-[16px] pl-[16px]">
          <input
            type={EInputType.NUMBER}
            placeholder="Введите сумму приза"
            min={Math.min(...pricesValues)}
            max={Math.max(...pricesValues)}
            value={priceValue ?? ''}
            onChange={handleInputChange}
            className="focus:outline-none focus:ring-1 focus:ring-green w-full pt-[13px] pb-[14px] px-3 pr-12 border rounded-xl leading-5"
          />
        </div>
        <div className="w-full text-txt_sec text-[14px] leading-[18.9px] max-w-[80%] mb-[24px] pr-[16px] pl-[16px]">
          Можно ввести любую сумму от 1000 ₽ до 20 000 ₽
        </div>
        <ul className="flex gap-x-[6px] overflow-x-scroll no-scrollbar pr-[16px] pl-[16px]">
          {pricesValues.map((price, index) => (
            <li
              key={price}
              onClick={() => {
                setPriceValue(price);
                setHighlightedIndex(index);
              }}
              className={`rounded-[12px] pt-[10px] pb-[10px] pr-[16px] pl-[16px] text-[14px] leading-[18.9px] flex-shrink-0 ${
                highlightedIndex === index ? 'bg-green text-white' : 'bg-light_grey text-header'
              }`}
            >
              {price} ₽
            </li>
          ))}
        </ul>
      </div>
      <div className="mr-[16px] ml-[16px] mt-[32px]">
        <Button
          text="Заказать"
          nameClass={EButtonClass.DEF}
          isLink={false}
          typeBtn={EButtonType.BUTTON}
          handle={() => setIsOpenConfirmOrder(true)}
          isDisabled={isSubmitDisabled}
        />
      </div>
      {isOpenNotification && (
        <SuccessNotification
          title={notificationMessage}
          setIsOpenNotification={setIsOpenNotification}
          button={
            <Button
              text="Хорошо"
              nameClass={EButtonClass.DEF}
              isLink={false}
              handle={() => setIsOpenNotification(false)}
            />
          }
          isSuccess={notificationMessage === SUCCESS_MSG}
        />
      )}
      {isOpenConfirmOrder && (
        <ConfirmAction
          title={`Вы действительно хотите заказать приз на сумму ${priceValue} ₽?`}
          btnTextSuccess="Заказать"
          btnTextNegative="Отмена"
          setIsOpenConfirmOrder={setIsOpenConfirmOrder}
          handleSuccessBtn={() => {
            setIsOpenConfirmOrder(false);
            handleSubmit();
          }}
          handleNegativeBtn={() => setIsOpenConfirmOrder(false)}
        />
      )}
      {isOpenAuthNotification && <SmallNotification text={CustomMsgs.NO_AUTH_LOG} isSuccess={false} />}
    </div>
  );
};

export default PrizesCategory;
