import React from 'react';

interface ISmallNotification {
  text: string;
  isSuccess?: boolean;
}

const SmallNotification: React.FC<ISmallNotification> = ({ text, isSuccess = true }) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 z-[12] bg-grey">
      <div className="w-[calc(100%-32px)] max-w-[343px] mx-auto bg-white rounded-[12px] p-[20px] flex gap-x-[8px] absolute bottom-[16px] left-[16px] right-[16px]">
        <div className="w-[24px] h-[24px] flex-shrink-0">
          {isSuccess ? (
            <img src="/Icons/success-small-notification.svg" alt="Success" />
          ) : (
            <img src="/Icons/no-success-small-notification.svg" alt="No success" />
          )}
        </div>
        <div className="text-main text-[14px] leading-[18.9px] mt-[4px]">{text}</div>
      </div>
    </div>
  );
};

export default SmallNotification;
