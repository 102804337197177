import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, ISteps, OPTIONS } from '../interfaces';
import { fetcher } from '../fetcher';

const useGetSteps = (): IApiResponseReturn<ISteps[]> => {
  const { data, error } = useSWR<IApiResponse<ISteps[]>>(EApiUrls.STEPS, fetcher, {
    ...OPTIONS,
    revalidateOnFocus: true,
    dedupingInterval: 1000,
    revalidateIfStale: true,
  });

  if (error) {
    console.error('Ошибка при загрузке шагов на главной: ', error);
  }

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetSteps;
