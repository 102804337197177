import React, { useEffect, useState } from 'react';
import { EInputType, InputField } from '../../components';
import { PageLayout } from '../../layout';
import {
  IGetUserInfo,
  useGetUserInfo,
  useGetCountries,
  useGetRegions,
  useGetCities,
  IRegion,
  ICity,
} from '../../../api';
import { GenderMap } from '../../../utils';

const PersonalInfoPage: React.FC = () => {
  const { data } = useGetUserInfo();

  const countryList = useGetCountries();
  const contactRegionList = useGetRegions(data?.contactInfo.address.country || null);
  const contactCityList = useGetCities(data?.contactInfo.address.region || null);
  const storeRegionList = useGetRegions(data?.storeInfo.country || null);
  const storeCityList = useGetCities(data?.storeInfo.region || null);

  const [formData, setFormData] = useState<IGetUserInfo>({
    id: 0,
    name: null,
    surname: null,
    patronymic: null,
    email: null,
    avatar: null,
    gender: '',
    birthDate: null,
    contactInfo: {
      phone: null,
      address: {
        country: null,
        region: null,
        city: null,
        zip: 0,
        street: null,
        house: null,
        apartment: null,
      },
    },
    storeInfo: {
      country: null,
      region: null,
      city: null,
      address: null,
      name: null,
    },
  });

  useEffect(() => {
    if (data && countryList?.data) {
      const getCountryNameById = (id: string | null) =>
        countryList.data?.find((country) => Number(country.id) === Number(id))?.name || null;

      const getRegionNameById = (regionList: IRegion[] | undefined, id: string | null) =>
        regionList?.find((region) => Number(region.id) === Number(id))?.name || null;

      const getCityNameById = (cityList: ICity[] | undefined, id: string | null) =>
        cityList?.find((city) => Number(city.id) === Number(id))?.name || null;

      setFormData({
        id: data.id,
        name: data.name,
        surname: data.surname,
        patronymic: data.patronymic,
        email: data.email,
        avatar: data.avatar,
        gender: GenderMap[data.gender || ''],
        birthDate: data.birthDate,
        contactInfo: {
          phone: data.contactInfo.phone,
          address: {
            country: getCountryNameById(data.contactInfo.address.country),
            region: getRegionNameById(contactRegionList?.data, data.contactInfo.address.region),
            city: getCityNameById(contactCityList?.data, data.contactInfo.address.city),
            zip: data.contactInfo.address.zip,
            street: data.contactInfo.address.street,
            house: data.contactInfo.address.house,
            apartment: data.contactInfo.address.apartment,
          },
        },
        storeInfo: {
          country: getCountryNameById(data.storeInfo.country),
          region: getRegionNameById(storeRegionList?.data, data.storeInfo.region),
          city: getCityNameById(storeCityList?.data, data.storeInfo.city),
          address: data.storeInfo.address,
          name: data.storeInfo.name,
        },
      });
    }
  }, [
    data,
    countryList?.data,
    contactRegionList?.data,
    contactCityList?.data,
    storeRegionList?.data,
    storeCityList?.data,
  ]);

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full pt-4 pb-20 px-4 bg-white rounded-lg justify-center self-start">
        <h2 className="text-2xl font-medium mb-8 text-header text-start">Персональные данные</h2>

        <div className="mb-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Личные данные</p>

          <InputField label="Имя" value={formData.name || ''} readonly={true} />
          <InputField label="Фамилия" value={formData.surname || ''} readonly={true} />
          <InputField label="Отчество" value={formData.patronymic || ''} readonly={true} />
          <InputField label="Пол" value={formData.gender || ''} readonly={true} />
          <InputField label="Дата рождения" type={EInputType.DATE} value={formData.birthDate || ''} readonly={true} />
        </div>

        <div className="mb-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Контактные данные</p>

          <InputField label="Email" type={EInputType.EMAIL} value={formData.email || ''} readonly={true} />
          <InputField
            label="Телефон"
            type={EInputType.PHONE}
            value={formData.contactInfo.phone || ''}
            readonly={true}
          />
        </div>

        <div className="mb-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Адрес продавца</p>

          <InputField label="Страна" value={formData.contactInfo.address.country || ''} readonly={true} />
          <InputField label="Регион" value={formData.contactInfo.address.region || ''} readonly={true} />
          <InputField
            label="Город или населенный пункт"
            value={formData.contactInfo.address.city || ''}
            readonly={true}
          />
          <InputField label="Улица" value={formData.contactInfo.address.street || ''} readonly={true} />
          <InputField label="Дом" value={formData.contactInfo.address.house || ''} readonly={true} />
          <InputField label="Квартира" value={formData.contactInfo.address.apartment || ''} readonly={true} />
          <InputField label="Индекс" value={String(formData.contactInfo.address.zip) || ''} readonly={true} />
        </div>

        <div className="mb-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Адрес магазина</p>

          <InputField label="Страна" value={formData.storeInfo.country || ''} readonly={true} />
          <InputField label="Регион" value={formData.storeInfo.region || ''} readonly={true} />
          <InputField label="Город или населенный пункт" value={formData.storeInfo.city || ''} readonly={true} />
          <InputField label="Адрес магазина" value={formData.storeInfo.address || ''} readonly={true} />
          <InputField label="Название магазина" value={formData.storeInfo.name || ''} readonly={true} />
        </div>
      </div>
    </PageLayout>
  );
};

export default PersonalInfoPage;
