import React, { useState } from 'react';
import { EButtonType } from '../../components';
import { CertificatesCategory, PrizesCategory } from '..';
import { PageLayout } from '../../layout';
import { useGetCatalog } from '../../../api';

enum ECatalogCategory {
  CERTIFICATE = 'certificate',
  PRIZE = 'prize',
}

const CatalogPage: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<ECatalogCategory>(ECatalogCategory.CERTIFICATE);

  const { data, isLoading, isError } = useGetCatalog();

  return (
    <PageLayout isBtnBack={false}>
      <h2 className="text-2xl pr-[16px] pl-[16px] leading-[28.8px] font-medium mb-[20px] mt-[30px] text-[24px] text-header">
        Каталог
      </h2>
      <div className="w-[calc(100% - 32px)] mr-[16px] ml-[16px] bg-light_grey rounded-[12px] grid grid-cols-2 p-[2px] mb-[32px]">
        <button
          type={EButtonType.BUTTON}
          onClick={() => setActiveCategory(ECatalogCategory.CERTIFICATE)}
          className={`p-[9px] text-[14px] rounded-[12px] font-medium leading-[18.9px] text-center ${
            activeCategory === ECatalogCategory.CERTIFICATE
              ? 'bg-white text-black shadow-[0px_0px_5px_0px_#0000001F] border-[0.5px] border-[#0000000A]'
              : 'text-secondary'
          }`}
        >
          Сертификат
        </button>
        <button
          type={EButtonType.BUTTON}
          onClick={() => setActiveCategory(ECatalogCategory.PRIZE)}
          className={`p-[9px] text-[14px] rounded-[12px] font-medium leading-[18.9px] text-center ${
            activeCategory === ECatalogCategory.PRIZE
              ? 'bg-white text-black shadow-[0px_0px_5px_0px_#0000001F] border-[0.5px] border-[#0000000A]'
              : 'text-secondary'
          }`}
        >
          Денежный приз
        </button>
      </div>

      {activeCategory === ECatalogCategory.CERTIFICATE ? (
        <CertificatesCategory data={data} isLoading={isLoading} isError={isError} />
      ) : (
        <PrizesCategory />
      )}
    </PageLayout>
  );
};

export default CatalogPage;
