import { useEffect, useState } from 'react';
import { getFetchRegions, IApiResponse, IRegion } from '..';

const useGetRegions = (countryId: string | null) => {
  const [response, setResponse] = useState<IApiResponse<IRegion[]> | null>(null);

  useEffect(() => {
    const fetchRegions = async () => {
      if (countryId) {
        try {
          const data = await getFetchRegions(countryId);
          setResponse(data);
        } catch (error) {
          console.error('Ошибка при загрузке регионов: ', error);
          setResponse(null);
        }
      } else {
        setResponse(null);
      }
    };

    fetchRegions();
  }, [countryId]);

  return response;
};

export default useGetRegions;
